<template>
    <v-modal
        :adaptive="true"
        :width="740"
        @before-open="modalOpened"
        height="auto"
        name="edit-shipment-modal"
        scrollable
    >
        <modal-content v-if="consignment" name="edit-shipment-modal">
            <div slot="header">
                <h2 class="font-normal">Edit details - Shipment ID {{ consignment.consignment_no }}</h2>
            </div>
            <div slot="body">
                <p
                    class="mb-4"
                >Edit details for a shipment. Note that this information will not be forwarded to the carrier.</p>

                <p class="mb-4 font-medium">Carrier</p>
                <div class="flex flex-wrap -mx-4">
                    <div class="w-full px-4 sm:w-1/2">
                        <modal-field class="w-full" label="Carrier" type="text">
                            <input
                                class="w-full mt-1"
                                type="text"
                                v-model="consignment.carrier_from_source"
                            />
                        </modal-field>
                    </div>
                    <div class="w-full px-4 sm:w-1/2">
                        <modal-field class="w-full" label="Carrier service" type="text">
                            <input
                                class="w-full mt-1"
                                type="text"
                                v-model="consignment.carrier_service_from_source"
                            />
                        </modal-field>
                    </div>
                </div>
                <hr class="my-6" />
                <p class="mb-4 font-medium">References</p>
                <div class="flex flex-wrap -mx-4">
                    <div class="w-full px-4 sm:w-1/2">
                        <modal-field class="w-full" label="Consignor ref" type="text">
                            <input class="w-full mt-1" type="text" v-model="consignment.order_no" />
                        </modal-field>
                    </div>
                    <div class="w-full px-4 sm:w-1/2">
                        <modal-field class="w-full" label="Consignee ref" type="text">
                            <input
                                class="w-full mt-1"
                                type="text"
                                v-model="consignment.consignee_reference"
                            />
                        </modal-field>
                    </div>
                </div>
                <hr class="my-6" />
                <p class="mb-4 font-medium">Contact details</p>
                <div class="flex flex-wrap mb-6 -mx-4">
                    <div class="w-full px-4 sm:w-1/2">
                        <modal-field class="w-full" label="Consignor e-mail adress" type="email">
                            <input
                                class="w-full mt-1"
                                type="text"
                                v-model="consignment.consignor_email"
                                :class="{'is-invalid': submitter && $v.consignment.consignor_email.$invalid}"
                            />
                        </modal-field>
                    </div>
                    <div class="w-full px-4 sm:w-1/2">
                        <modal-field class="w-full" label="Consignor phone number" type="text">
                            <input
                                class="w-full mt-1"
                                type="text"
                                v-model="consignment.consignor_phone"
                                :class="{'is-invalid': submitter && $v.consignment.consignor_phone.$invalid}"
                            />
                        </modal-field>
                    </div>
                </div>
                <div class="flex flex-wrap -mx-4">
                    <div class="w-full px-4 sm:w-1/2">
                        <modal-field class="w-full" label="Pickup e-mail address" type="email">
                            <input
                                class="w-full mt-1"
                                type="text"
                                v-model="consignment.pickup_email"
                                :class="{'is-invalid': submitter && $v.consignment.pickup_email.$invalid}"
                            />
                        </modal-field>
                    </div>
                    <div class="w-full px-4 sm:w-1/2">
                        <modal-field class="w-full" label="Pickup phone number" type="text">
                            <input
                                class="w-full mt-1"
                                type="text"
                                v-model="consignment.pickup_phone"
                                :class="{'is-invalid': submitter && $v.consignment.pickup_phone.$invalid}"
                            />
                        </modal-field>
                    </div>
                </div>
                <hr class="my-6" />
                <p class="mb-4 font-medium">Prices</p>
                <div class="flex flex-wrap -mx-4">
                    <div class="w-full px-4 sm:w-1/2">
                        <modal-field class="w-full" label="Price amount" type="number">
                            <input
                                :value="consignment.price_data.amount"
                                class="w-full mt-1"
                                type="text"
                                @input="updatePriceAmount($event.target.value)"
                            />
                        </modal-field>
                    </div>
                    <div class="w-full px-4 sm:w-1/2">
                        <modal-field class="w-full" label="Price currency" type="text">
                            <input
                                :value="consignment.price_data.currency"
                                class="w-full mt-1"
                                type="text"
                                @input="updatePriceCurrency($event.target.value)"
                            />
                        </modal-field>
                    </div>
                </div>
            </div>
            <div slot="footer">
                <div class="flex justify-end w-full">
                    <div class="flex">
                        <button
                            @click="$modal.hide('edit-shipment-modal')"
                            class="px-8 mr-4 btn-grey-outline"
                        >Cancel</button>

                        <button
                            :disabled="loading"
                            @click="updateShipment"
                            class="px-10 btn-primary"
                        >Save</button>
                    </div>
                </div>
            </div>
        </modal-content>
    </v-modal>
</template>
<script>
import axios from 'axios'
import { required, email } from 'vuelidate/lib/validators'

export default {
    name: 'EditShipmentModal',
    props: {
        shipment: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            consignment: null,
            confirmInput: '',
            loading: false,
            submitter: false,
            isPriceAmountChanged: false,
        }
    },
    computed: {
        endpoint() {
            if (!this.consignment) {
                return '';
            }

            return this.$apiUrl.consignments.base + `/${this.consignment.uuid}`;
        }
    },
    methods: {
        modalOpened() {
            this.confirmInput = '';
            this.fetchExtraData();
        },
        updatePriceAmount(value) {
            this.isPriceAmountChanged = true;
            this.consignment.price_data.amount = value;
        },
        updatePriceCurrency(value) {
            this.isPriceCurrencyChanged = true;
            this.consignment.price_data.currency = value;
        },
        getPriceParams() {
            if (this.isPriceAmountChanged || this.isPriceCurrencyChanged) {
                return { amount: this.consignment.price_data.amount, currency: this.consignment.price_data.currency };
            }

            return null;
        },
        async updateShipment() {
            this.submitter = true;

            if (this.$v.$invalid) {
                return;
            }

            const priceParams = this.getPriceParams();

            try {
                const promises = [axios.patch(this.endpoint, this.consignment)]
                if (priceParams) {
                    promises.push(axios.patch(`${this.endpoint}/prices`, priceParams));
                }

                await Promise.all(promises);

                this.$snotify.success(`Consignment updated`)
                this.$emit('updated');
            } catch (error) {
                this.$snotify.warning(`Some fields failed to validate`)
            }
        },
        async fetchExtraData() {
            try {
                const { data } = await axios.get(
                    this.$apiUrl.consignments.base + `/${this.shipment.uuid}`
                );
                this.consignment = data.data;
                this.loading = false;
            } catch (error) {
                this.loading = false;
            }
        },
    },

    watch: {
        consignment() {
            this.submitter = false;
        }
    },
    
    validations: {
        consignment: {
            consignor_email: {
                required,
                email,
            },
            pickup_email: {
                required,
                email,
            },
            consignor_phone: {
                required
            },
            pickup_phone: {
                required
            }
        },
    }
}
</script>
